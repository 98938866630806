import './App.css';

function App() {
  return (
    <div class="flex items-center justify-center h-screen bg-gray-100">
      <div class="container w-1/2">
        <div class="bg-white rounded-lg shadow-lg p-5 md:p-20 mx-2">
          <div class="text-center">
            <h1 class='text-xl md:text-3xl mt-10'>Coming Soon</h1>
          </div>
          <div class="flex flex-wrap mt-10 justify-center">
            <div class="m-3">
              <a href="https://www.facebook.com/fintel_vn" title="Fintel On Facebook"
                class="md:w-32 bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-blue-600 hover:border-blue-600 hover:bg-blue-600 hover:text-white shadow-md py-2 px-6 inline-flex items-center">
                <span class="mx-auto">Facebook</span>
              </a>
            </div>
            <div class="m-3">
              <a href="https://twitter.com/fintel_vn" title="Fintel On Twitter"
                class="md:w-32 bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-blue-500 hover:border-blue-500 hover:bg-blue-500 hover:text-white shadow-md py-2 px-6 inline-flex items-center">
                <span class="mx-auto">Twitter</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
